<template>
  <div>
    <Multiselect
      v-model="selection"
      style="margin-top: 0.5em;"
      class="search-usergroups"
      :options="results"
      :options-limit="10"
      :allow-empty="false"
      track-by="id"
      label="display_name"
      :reset-after="false"
      select-label=""
      selected-label=""
      deselect-label=""
      :searchable="true"
      :placeholder="placeholder"
      :show-no-results="true"
      :loading="loading"
      :clear-on-select="false"
      :preserve-search="false"
      @search-change="search"
      @close="select"
    >
      <template slot="clear">
        <div
          v-if="selection"
          class="multiselect__clear"
          @click.prevent.stop="selection = null"
        >
          <b-icon-x font-scale="2" />
        </div>
      </template>
      <span slot="noResult">
        {{ $t('searchbars.noResult') }}
      </span>
      <span slot="noOptions">
        {{ $t('searchbars.noOptions') }}
      </span>
    </Multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

import { mapState, mapActions } from 'vuex';

export default {
  name: 'SearchOrganisations',

  components: {
    Multiselect
  },

  props: {
    usergroup: {
      type: Object,
      default: () => { return {}; }
    },
    resetAfter: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: 'Ajouter cette organisation à des groupes d\'organisations'
    },
    currentOrganisations: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },

  data() {
    return {
      selection: null,
      loading: false,
      text: null,
      results: []
    };
  },

  computed: {
    ...mapState('organisations', [
      'organisationsList'
    ])
  },

  watch: {
    async text(newValue) {
      await this.searchOrganisations(newValue);
    }
  },

  async created() {
    if (this.usergroup && this.usergroup.organisation && this.usergroup.organisation.id) {
      this.selection = this.usergroup;
    } else if (this.usergroup && this.usergroup.id) {
      this.selection = this.usergroup;
    }
    if (this.organisationsList && this.organisationsList.length) {
      this.results = this.organisationsList
        .filter(el => this.currentOrganisations.every(e => e.organisation.id !== el.id));
    } else {
      await this.searchOrganisations();
    }
  },

  methods: {
    ...mapActions('organisations', [
      'SEARCH_ORGANISATIONS_LIST'
    ]),

    async searchOrganisations(query) {
      try {
        this.loading = true;
        await this.SEARCH_ORGANISATIONS_LIST({ text: query });
        if (query) {
          this.results = this.organisationsList
            .filter(el => this.currentOrganisations.every(e => e.organisation.id !== el.id));
        } else {
          this.results = this.organisationsList
            .filter(el => this.currentOrganisations.every(e => e.organisation.id !== el.id));
        }
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },

    search(text) {
      this.text = text;
    },

    select() {
      this.$emit('select', this.selection);
    },

    clear() {
      this.selection = null;
    }
  }
};
</script>
