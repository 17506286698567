<template>
  <div>
    <form>
      <h3 class="line-header">
        <img
          src="@/assets/icons/user-group.svg"
          alt="Icone entreprise"
        > Groupe d'organisations
      </h3>
      <!-- NAME -->
      <div
        v-if="isFieldVisible('name', formConfig.hiddenFields)"
        class="form-row"
      >
        <div class="form-group col-12">
          <label :class="isFieldRequired('name', formConfig.requiredFields)">
            {{ $t('forms.sphere.name.label') }}
          </label>
          <p>
            {{ $t('forms.sphere.name.help') }}
          </p>
          <ValidationProvider
            v-slot="{ classes, errors }"
            ref="display_name"
            :rules="isFieldRequired('name', formConfig.requiredFields)"
          >
            <div
              class="control"
              :class="classes"
            >
              <input
                v-model="formData.displayName"
                v-sanitize
                class="form-control"
                type="text"
                :placeholder="$t('forms.sphere.name.label')"
              >
              <span class="form-errors">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </div>
      </div>
      <!-- DESCRIPTION -->
      <div
        v-if="isFieldVisible('description', formConfig.hiddenFields)"
        class="form-row"
      >
        <div class="form-group col-12">
          <label :class="isFieldRequired('description', formConfig.requiredFields)">
            {{ $t('forms.sphere.description') }}
          </label>
          <ValidationProvider
            v-slot="{ classes, errors }"
            ref="display_name"
            :rules="isFieldRequired('description', formConfig.requiredFields)"
          >
            <div
              class="control"
              :class="classes"
            >
              <textarea
                v-model="formData.description"
                v-sanitize
                class="form-control"
              />
              <span class="form-errors">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import { ValidationProvider, extend, configure } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
extend('required', {
  ...required,
  message: 'Ce champ est requis'
});
configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid'
  }
});

export default {
  name: 'SphereForm',

  components: {
    ValidationProvider
  },

  props: {
    sphere: {
      type: Object,
      default: () => { return {}; }
    }
  },

  data() {
    return {
      formData: {
        displayName: null,
        description: null
      }
    };
  },

  computed: {
    ...mapState('usergroups', ['currentUsergroup']),

    formConfig() {
      return this.$config.forms.sphere;
    },
  },

  watch: {
    formData: {
      deep: true,
      handler(newValue) {
        this.$emit('update', {
          ...this.currentUsergroup,
          ...newValue.displayName && { display_name: newValue.displayName },
          ...newValue.description && { description: newValue.description }
        });
      }
    }
  },

  created() {
    this.formData.displayName = this.sphere.display_name;
    this.formData.description = this.sphere.description;
  },

  methods: {
    saveSphere() {
      const data = {
        ...this.currentUsergroup,
        ...this.formData.displayName && { display_name: this.formData.displayName },
        ...this.formData.description && { description: this.formData.description }
      };
      this.$emit('save', data);
    }
  }
};
</script>
