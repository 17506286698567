<template>
  <div class="card-container">
    <h4 v-if="sphere.display_name">
      {{ sphere.display_name }}
    </h4>
    <h4 v-else class="placeholder">
      {{ $t('words.new') }}
    </h4>
    <b-avatar src="" size="7rem"></b-avatar>
  </div>
</template>

<script>
export default {
  name: 'SphereCard',

  props: {
    sphere: {
      type: Object,
      default: () => { return {} }
    }
  }
}
</script>

<style scoped>
h4 {
  margin-bottom: 1rem !important;
}
.placeholder {
  color: rgb(196, 196, 196);
}
</style>
